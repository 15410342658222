import React, { useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Navbar from '../components/Navbar';
import { EditorState, convertToRaw } from 'draft-js'; // Import EditorState and related functions
import { Editor } from 'react-draft-wysiwyg'; // Import Editor from react-draft-wysiwyg
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const CreateProduct = () => {
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [productData, setProductData] = useState({
        name: '',
        description: EditorState.createEmpty(),
        price: '',
        category: '',
        subcategory: '',
        discountedPrice: '',
        productImages: [],
        quantity: '',
    });

    const quillModules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    };

    const quillFormats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];


    const navigate = useNavigate();
    const dropzoneRef = useRef(null);

    const handleDescriptionChange = (editorState) => {
        setProductData((prevData) => ({ ...prevData, description: editorState }));
    };
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'price') {
            const price = parseFloat(value);
            const discountedPrice = price * 1;
            setProductData((prevData) => ({ ...prevData, [name]: value, discountedPrice }));
        } else {
            setProductData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const accept = {
        'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.bmp'],
    };

    const handleImageDrop = (acceptedFiles) => {
        setProductData((prevData) => ({ ...prevData, productImages: [...prevData.productImages, ...acceptedFiles] }));
    };

    const removeImage = (index) => {
        const updatedImages = [...productData.productImages];
        updatedImages.splice(index, 1);
        setProductData((prevData) => ({ ...prevData, productImages: updatedImages }));
    };

    const validateForm = () => {
        const errors = {};
        const requiredFields = ['name', 'description', 'price', 'category', 'discountedPrice', 'quantity'];
        requiredFields.forEach((field) => {
            if (!productData[field]) {
                errors[field] = 'This field is required';
            }
        });
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const formData = new FormData();
        formData.append('name', productData.name);
        formData.append('description', JSON.stringify(convertToRaw(productData.description.getCurrentContent())));
        formData.append('price', productData.price);
        formData.append('category', productData.category);
        formData.append('discountedPrice', productData.discountedPrice);
        formData.append('quantity', productData.quantity);
        formData.append('subcategory', productData.subcategory);

        productData.productImages.forEach((productImage, index) => {
            formData.append('productImages', productImage);
        });

        try {
            const response = await axios.post('https://server.apertscribe.co.zw/api/v1/products', formData);
            toast.success('Product created successfully', { position: toast.POSITION.BOTTOM_RIGHT });
            navigate(`/${productData.category}`);
        } catch (error) {
            console.error('Error creating product:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
            }
            toast.error('Error creating product', { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    return (
        <div className='p-5'>
            <Navbar />
            <h1 className='text-2xl font-extrabold mt-4 '>CREATE A PRODUCT HERE</h1>
            <form onSubmit={handleFormSubmit} className='flex flex-col mt-5'>
                <div className='flex flex-col md:flex-row'>
                    <div className='flex flex-col gap-5 w-full md:w-1/2'>
                        <div className='flex flex-col md:flex-row w-full justify-start md:justify-between gap-4 md:items-center'>
                            <h1 className='text-xl'>Product Name</h1>
                            <input className='border w-[300px] border-[#949494] text-[#9F9F9F] px-3 py-2 text-start rounded-sm' type="text" name="name" value={productData.name} onChange={handleInputChange} required />
                        </div>
                        <div className='flex gap-4  w-full flex-col md:flex-row justify-start md:justify-between '>
                            <h1 className='text-xl'>Description</h1>
                            <Editor
                                editorState={productData.description}
                                toolbarClassName="toolbar-class"
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                onEditorStateChange={handleDescriptionChange}
                            />
                        </div>
                        <div className='flex w-full mt-[15%] flex-col md:flex-row justify-start md:justify-between gap-4 md:items-center'>
                            <h1 className='text-xl'>Price</h1>
                            <input className='border border-[#949494] text-[#9F9F9F] w-[300px] px-3 py-2 text-start rounded-sm' type="number" name="price" value={productData.price} onChange={handleInputChange} required />
                        </div>
                        <div className='flex w-full flex-col md:flex-row gap-4 justify-start md:justify-between'>
                            <h1 className='text-xl'>Category</h1>
                            <select
                                className="border border-[#949494] px-3 py-2 rounded-sm w-[300px]"
                                name="category"
                                value={productData.category}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select category</option>
                                <option value="Bedroom">Bedroom</option>
                                <option value="Dining">Dining</option>
                                <option value="Electricals">Electricals</option>
                                <option value="Kitchen">Kitchen</option>
                                <option value="Lounge">Lounge</option>
                                <option value="Office">Office</option>
                                <option value="School">School</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col gap-5 w-1/2 md:ml-3'>
                        <div className='flex w-full flex-col md:flex-row justify-start md:justify-between gap-4 md:items-center'>
                            <h1 className='text-xl'>Quantity</h1>
                            <input type="number"
                                className="border border-[#949494] px-3 py-2 rounded-sm w-[300px]"
                                name="quantity" value={productData.quantity} onChange={handleInputChange} />
                        </div>
                        <div className='flex w-full flex-col md:flex-row justify-start md:justify-between gap-4 md:items-center'>
                            <h1 className='text-xl'>Product Images</h1>
                            <Dropzone onDrop={handleImageDrop} accept={accept} ref={dropzoneRef} multiple={true}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} className="dropzone">
                                        <input {...getInputProps()} accept="image/*" multiple={true}   />
                                        <p className='border bg-gray-400 rounded-sm shadow-sm px-5 py-2 '>Select pictures</p>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                        <div className="mt-4 flex gap-5">
                            {productData.productImages.map((image, index) => (
                                <div key={index} className="flex flex-col justify-center gap-2 mt-2">
                                    <img src={URL.createObjectURL(image)} alt={`Product ${index + 1}`} className="h-20 w-20 object-cover rounded mr-2" />
                                    <button type="button" onClick={() => removeImage(index)} className="bg-red-500 text-white px-2 py-1 rounded">Remove</button>
                                </div>
                            ))}
                        </div>
                        <div className='flex w-full flex-col md:flex-row gap-4 justify-start md:justify-between'>
                            <h1 className='text-xl'>Subcategory</h1>
                            <select
                                className="border border-[#949494] px-3 py-2 rounded-sm w-[300px]"
                                name="subcategory"
                                value={productData.subcategory}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select subcategory</option>
                                <optgroup label="Office">
                                    <option value="ExecutiveDesks">Executive Desks</option>
                                    <option value="Cabinets">Cabinets</option>
                                    <option value="Visitorschairs">Visitors' Chairs</option>
                                    <option value="Swivelchairs">Swivel chairs</option>
                                    <option value="OtherOffice">Other Office</option>
                                </optgroup>
                                <optgroup label="Bedroom">
                                    <option value="Beds">Beds</option>
                                    <option value="suites">Bedroom Suites</option>
                                    <option value="Wardrobes">Wardrobes</option>
                                    <option value="OtherBedroom">Other Bedroom</option>
                                </optgroup>
                                <optgroup label="Kitchen">
                                    <option value="KitchenChairsUnits">Kitchen Chairs/Units</option>
                                    <option value="Fridges">Fridges</option>
                                    <option value="Stoves">Stoves</option>
                                    <option value="Microwaves">Microwaves</option>
                                    <option value="OtherKitchen">Other Kitchen</option>
                                </optgroup>
                                <optgroup label="Lounge">
                                    <option value="Coffetables">Coffe tables</option>
                                    <option value="PlasmaStands">Plasma Stands</option>
                                    <option value="Sofas">Sofas</option>
                                    <option value="OtherLounge">Other Lounge</option>
                                </optgroup>
                                <optgroup label="Electricals">
                                    <option value="TVs">TVs</option>
                                    <option value="Phones">Phones</option>
                                    <option value="OtherElectrials">Other Electrials</option>
                                </optgroup>
                                <optgroup label="Dining">
                                    <option value="Dining">Dining</option>

                                </optgroup>
                                <optgroup label="School">
                                    <option value="School">School Furniture</option>

                                </optgroup>

                            </select>
                        </div>
                    </div>
                </div>
                <div className='flex md:items-center justify-center mt-3'>
                    <button type="submit" className='bg-red-800 w-[250px] px-4 rounded-sm py-2 text-white font-bold' disabled={isSubmitting} >Create Product</button>
                </div>
                <div className="text-red-600 mt-2">
                    {Object.values(validationErrors).map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
            </form>
        </div>
    );
};

export default CreateProduct;
