import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../components/Navbar';
import Dropzone from 'react-dropzone';


const UpdateProduct = () => {
    const { product_id } = useParams();
    const [productData, setProductData] = useState({
        name: '',
        description: '',
        price: '',
        category: '',
        discounted_price: '',
        quantity: 100,
        productImages: []
    });
    const navigate = useNavigate();

    const dropzoneRef = useRef(null);

    const accept = {
        'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.bmp'],
    };

    useEffect(() => {
        axios
            .get(`https://server.apertscribe.co.zw/api/v1/products/product/${product_id}`)
            .then((response) => {
                setProductData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching product:', error);
            });
    }, [product_id]);

    const handleInputChange = (e) => {
        const { name, files } = e.target;

        if (name === 'productImages') {
            const newImages = [...files];
            setProductData((prevData) => ({ ...prevData, picture_path: [...prevData.picture_path, ...newImages] }));
        } else {
            // Check if preventDefault is cancelable before calling it
            if (e.cancelable) {
                e.preventDefault();
            }
            setProductData((prevData) => ({ ...prevData, [name]: e.target.value }));
        }
    };







    const handleImageDrop = (acceptedFiles) => {
        setProductData((prevData) => ({
            ...prevData,
            picture_path: [...prevData.picture_path, ...acceptedFiles],
        }));
    };

    const removeImage = (index) => {
        const updatedImages = [...productData.picture_path];
        updatedImages.splice(index, 1);
        setProductData((prevData) => ({ ...prevData, picture_path: updatedImages }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            console.log('Submitting form with data:', productData);

            const formData = new FormData();
            formData.append('name', productData.name);
            formData.append('description', productData.description);
            formData.append('category', productData.category);
            formData.append('price', productData.price);
            formData.append('discounted_price', productData.discounted_price);
            formData.append('quantity', productData.quantity);

            // Log the productImages array before appending
            console.log('productImages before appending:', productData.picture_path);

            // Append all images, whether existing or new
            productData.picture_path.forEach((file, index) => {
                formData.append(`productImages`, file);
            });

            console.log('Form data before sending:', formData);

            await axios.put(
                `https://server.apertscribe.co.zw/api/v1/products/product/${product_id}`,
                formData
            );

            toast.success('Product updated successfully', {
                position: toast.POSITION.TOP_CENTER,
            });

            navigate(`/viewproduct/${product_id}`);
        } catch (error) {
            console.error('Error updating product:', error);
            console.log('Axios response:', error.response); // Log the response details
            alert('Error updating product');
        }
    };



    return (
        <div>
            <Navbar />
            <h2 className='md:text-2xl text-center md:text-start mt-5 font-bold text-[#4F4F4F]'>UPDATE PRODUCT : {productData.name}</h2>
            <form onSubmit={handleFormSubmit} encType="multipart/form-data" className='flex flex-col p-5 mt-6 gap-5 shadow-md'>


                <div className='flex md:flex-row flex-col'>
                    <div className='md:w-1/2 w-full gap-8 flex flex-col'>
                        <div className='flex md:flex-row flex-col  w-full md:justify-between gap-4 md:items-center'>
                            <h1 className='text-xl text-start'>Product Name</h1>
                            <input type="text" name="name" className='border w-[300px] border-[#949494] text-[#9F9F9F] px-3 py-2 text-start rounded-sm' value={productData.name} onChange={handleInputChange} required />
                        </div>
                        <div className='flex md:flex-row flex-col gap-4 w-full justify-between '>
                            <h1 className=' text-start text-xl'>Description</h1>
                            <textarea className='border border-[#949494] w-[300px] h-[200px] text-[#9F9F9F] rounded-sm' name="description" value={productData.description} onChange={handleInputChange} required />
                        </div>

                        <div className='flex md:flex-row flex-col w-full justify-between'>
                            <h1 className='text-xl text-start'>Product Images</h1>
                            <Dropzone onDrop={handleImageDrop} accept={accept} ref={dropzoneRef} multiple={true}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} className="dropzone">
                                        <input {...getInputProps()} accept="image/*" multiple={true} />
                                        <p className='border font-bold text-white bg-red-700 rounded-sm shadow-sm px-5 py-2 '>SELECT NEW *IMAGES</p>
                                    </div>
                                )}
                            </Dropzone>


                        </div>

                        <div className='flex'>
                            {productData.picture_path && productData.picture_path.map((image, index) => (
                                <div className='flex flex-col gap-3' key={index}>
                                    {image instanceof File && (
                                        <img
                                            src={URL.createObjectURL(image)}
                                            alt={`Product Image ${index + 1}`}
                                            className='w-[100px] h-[100px] mt-2'
                                        />
                                    )}
                                    {image instanceof File && (
                                        <button className='bg-red-400 rounded-sm px-2 py-1 text-white font-bold' onMouseDown={(e) => { e.preventDefault(); removeImage(index); }}>
                                            Remove Image
                                        </button>
                                    )}
                                </div>
                            ))}


                        </div>

                        <div className='flex md:flex-row flex-col w-full  justify-between'>
                            <h1 className='text-xl text-start'>Category</h1>
                            <select className="border mt-4 border-[#949494] px-3 py-2 rounded-sm w-[300px]" name="category" value={productData.category} onChange={handleInputChange} required>
                                <option value="Bedroom">Bedroom</option>
                                <option value="Dining">Dining</option>
                                <option value="Electricals">Electricals</option>
                                <option value="Kitchen">Kitchen</option>
                                <option value="Lounge">Lounge</option>
                                <option value="Office">Office</option>
                                <option value="School">School</option>
                            </select>
                        </div>

                    </div>
                    <div className='flex w-full md:w-1/2 gap-8 flex-col  md:px-5'>
                        <div className='flex md:flex-row flex-col w-full md:justify-between gap-4 md:items-center'>
                            <h1 className='text-xl text-start'>Price</h1>
                            <input type="number" name="price" className='border border-[#949494] text-[#9F9F9F] w-[300px] px-3 py-2 text-start rounded-sm' value={productData.price} onChange={handleInputChange} required />
                        </div>

                        <div className='flex md:flex-row flex-col w-full md:justify-between gap-4 md:items-center'>
                            <h1 className='text-xl text-start'>Discounted Price</h1>

                            <input
                                type="number"
                                name="discounted_price"
                                className="border border-[#949494] px-3 py-2 rounded-sm w-[300px]"
                                onChange={handleInputChange}
                                value={productData.discounted_price}
                                onWheel={(e) => e.preventDefault()}  // Disable scrolling for number input
                            />
                        </div>

                        <div className='flex md:flex-row flex-col w-full md:justify-between gap-4 md:items-center'>
                            <h1 className='text-xl text-start'>Quantity</h1>

                            <input
                                type="number"
                                name="quantity"
                                className="border border-[#949494] px-3 py-2 rounded-sm w-[300px]"
                                value={productData.quantity}
                                onChange={handleInputChange}

                                onWheel={(e) => e.preventDefault()}  // Disable scrolling for number input
                            />
                        </div>

                    </div>

                </div>
                <ToastContainer />
                <div className='flex justify-center'>
                    <button className="bg-[#060B24] rounded-sm font-bold text-white py-2 px-4 w-[200px] flex justify-center" type="submit">Update Product</button>
                </div>


            </form>
        </div>
    );
};

export default UpdateProduct;
