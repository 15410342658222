import React, { lazy, Suspense, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Vieworder from '../pages/Vieworder';
import CreateProduct from '../pages/Createproduct';
import UpdateProduct from '../pages/Updateproduct';
import Login from '../pages/Login';

const Homepage = lazy(() => import('../pages/Homepage'));
const Discount = lazy(() => import('../pages/Discount'));

const Bedroom = lazy(() => import('../pages/Bedroom'));
const Dining = lazy(() => import('../pages/Dining'));
const Electricals = lazy(() => import('../pages/Electricals'));
const Kitchen = lazy(() => import('../pages/Kitchen'));
const Lounge = lazy(() => import('../pages/Lounge'));
const Office = lazy(() => import('../pages/Office'));
const Viewproduct = lazy(() => import('../pages/Viewproduct'));
const Schoolfurniture = lazy(() => import('../pages/Schoolfurniture'));


const Beds = lazy(() => import('../categories/Beds'));
const Cabinets = lazy(() => import('../categories/Cabinets'));
const Coffetables = lazy(() => import('../categories/Coffetables'));
const ExecutiveDesks = lazy(() => import('../categories/ExecutiveDesks'));
const Fridges = lazy(() => import('../categories/Fridges'));
const KitchenChairsUnites = lazy(() => import('../categories/KitchenChairsUnites'));
const OtherBedroom = lazy(() => import('../categories/OtherBedroom'));
const Otheroffice = lazy(() => import('../categories/Otheroffice'));
const OtherElectricals = lazy(() => import('../categories/OtherElectricals'));
const OtherKitchen = lazy(() => import('../categories/OtherKitchen'));
const Stoves = lazy(() => import('../categories/Stoves'));
const Suites = lazy(() => import('../categories/Suites'));
const TVs = lazy(() => import('../categories/TVs'));
const Wadrobes = lazy(() => import('../categories/Wadrobes'));
const Phones = lazy(() => import('../categories/Phones'));
const Microwaves = lazy(() => import('../categories/Microwaves'));
const Sofas = lazy(() => import('../categories/Sofas'));
const PlasmaStands = lazy(() => import('../categories/PlasmaStands'));
const OtherLounge = lazy(() => import('../categories/OtherLounge'));
const Visitorschairs = lazy(() => import('../categories/Visitorschairs'));
const Swivelchairs = lazy(() => import('../categories/Swivelchairs'));








const Routesfile = () => {
   
 

    return (
        <Suspense fallback={<div className="loader">
            <div className="justify-content-center jimu-primary-loading"></div>
        </div>}>
            <Routes>
               
                <Route path="/" element={<Login />} />
                <Route path="/Homepage" element={<Homepage />} />\
                <Route path="/Bedroom" element={<Bedroom />} />
                <Route path="/Dining" element={<Dining />} />
                <Route path="/Electricals" element={<Electricals />} />
                <Route path="/Kitchen" element={<Kitchen />} />
                <Route path="/Lounge" element={<Lounge />} />
                <Route path="/Office" element={<Office />} />
                <Route path="/school" element={<Schoolfurniture />} />
                <Route path="/Createproduct" element={<CreateProduct />} />
                <Route path="/Viewproduct/:product_id" element={<Viewproduct />} />
                <Route path="/Vieworder/:order_id" element={<Vieworder />} />
                <Route path="/Updateproduct/:product_id" element={<UpdateProduct />} />
                <Route path="/Beds" element={<Beds  />} />
                <Route path="/Cabinets" element={<Cabinets  />} />
                <Route path="/Coffetables" element={<Coffetables />} />
                <Route path="/ExecutiveDesks" element={<ExecutiveDesks />} />
                <Route path="/Fridges" element={<Fridges />} />
                <Route path="/KitchenChairsUnites" element={<KitchenChairsUnites  />} />
                <Route path="/OtherBedroom" element={<OtherBedroom  />} />
                <Route path="/Otheroffice" element={<Otheroffice  />} />
                <Route path="/OtherElectricals" element={<OtherElectricals />} />
                <Route path="/OtherKitchen" element={<OtherKitchen />} />
                <Route path="/OtherLounge" element={<OtherLounge />} />
                <Route path="/Visitorschairs" element={<Visitorschairs />} />
                <Route path="/Swivelchairs" element={<Swivelchairs />} />

                <Route path="/Stoves" element={<Stoves />} />
                <Route path="/Suites" element={<Suites />} />
                <Route path="/TVs" element={<TVs />} />
                <Route path="/Wadrobes" element={<Wadrobes />} />
                <Route path="/Phones" element={<Phones />} />
                <Route path="/Microwaves" element={<Microwaves />} />
                <Route path="/Sofas" element={<Sofas />} />
                <Route path="/PlasmaStands" element={<PlasmaStands />} /> 
                
                <Route path="/Discount" element={<Discount />} />





                
            </Routes>
        </Suspense>
    );
};

export default Routesfile;
