import React, { useState, useEffect, useRef } from 'react';
import logo from "../assets/logo.png"
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const menuRef = useRef(null);


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const closeMenu = (e) => {
            if (menuRef.current && menuRef.current.contains(e.target)) {
                return;
            }
            setIsMenuOpen(false);
        };

        document.addEventListener('click', closeMenu);

        return () => {
            document.removeEventListener('click', closeMenu);
        };
    }, [menuRef]);

    const handleLogout = async () => {

        navigate('/');


    };



    return (
        <div className='shadow-md w-full md:w-[100%] h-[80px] justify-between flex px-11'>
            <Link to="/Homepage">
                <img src={logo} alt="Fourtech" className='w-[150px] f h-[70px]' />
            </Link>
            <div className='md:flex hidden'>
                <ul className='flex gap-9 justify-center items-center font-bold text-lg'>

                    <li>
                        <button >
                            <Link to="/Bedroom">Bedroom</Link>
                        </button>
                    </li>
                    <li>
                        <button >
                            <Link to="/Dining">Dining</Link>
                        </button>
                    </li>
                    <li>
                        <button >
                            <Link to="/Electricals">Electricals</Link>
                        </button>
                    </li>
                    <li>
                        <button >
                            <Link to="/Kitchen">Kitchen</Link>
                        </button>
                    </li>
                    <li>
                        <button >
                            <Link to="/Lounge">Lounge</Link>
                        </button>
                    </li>
                    <li>
                        <button >
                            <Link to="/Office">Office</Link>
                        </button>
                    </li>
                    <li>
                        <button >
                            <Link to="/School">School</Link>
                        </button>
                    </li>
                    <li>
                        <button className='bg-[#BB0000] rounded-sm py-2 px-5 text-white ' onClick={handleLogout}>
                            Logout
                        </button>
                    </li>

                </ul>
            </div>
            <div className='md:hidden mt-8' ref={menuRef}>
                <button onClick={toggleMenu}>
                    <MenuIcon />
                </button>
                {isMenuOpen && (
                    <div className='fixed top-[10%] right-1 w-[30%] flex flex-col h-full bg-white gap-4 items-center text-lg'>
                        <button className='border-b-2 mb-3 mt-3' >
                            <Link to="/">Home</Link>
                        </button>
                        <button className='border-b-2 mb-3 mt-3' >
                            <Link to="/Bedroom">Bedroom</Link>
                        </button>
                        <button className='border-b-2 mb-3 mt-3' >
                            <Link to="/Lounge">Lounge</Link>
                        </button>
                        <button className='border-b-2 mb-3 mt-3' >
                            <Link to="/Dining">Dining</Link>
                        </button>
                        <button className='border-b-2 mb-3 mt-3' >
                            <Link to="/Electricals">Electricals</Link>
                        </button>
                        <button className='border-b-2 mb-3 mt-3' >
                            <Link to="/Kitchen">Kitchen</Link>
                        </button>
                        <button className='border-b-2 mb-3 mt-3' >
                            <Link to="/Office">Office</Link>
                        </button>
                        <button className='border-b-2 mb-3 mt-3' >
                            <Link to="/School">School</Link>
                        </button>
                        <button className='bg-[#BB0000] rounded-sm py-2 px-5 text-white' onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
